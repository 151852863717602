import React from "react";
import Slider from "react-slick";
import { Container } from "react-bootstrap";
import NewsCard from "./NewsCard/NewsCard";
import your_story from "../../../../../assets/images/news/your_story.jpeg";
import cxo_today from "../../../../../assets/images/news/cxo_today.jpeg";
import financial_express from "../../../../../assets/images/news/financial_express.jpeg";
import nft_metta from "../../../../../assets/images/news/nft_metta.jpeg";
import "./NewsSection.scss";

const newsData = [
  {
    date: "December 6, 2023",
    title: "Heard of crypto romance scams?",
    subTitle: "The online dating world is reeling under it",
    link: "https://www.financialexpress.com/business/digital-transformation-heard-of-crypto-romance-scams-the-online-dating-world-is-reeling-under-itnbsp-3329117/",
    img: financial_express,
  },
  {
    date: "December 21, 2023",
    title: "The revolutionised crypto ecosystem: The age of CeDefi",
    subTitle: "",
    link: "https://yourstory.com/the-decrypting-story/the-revolutionised-crypto-ecosystem-the-age-of-cedefi",
    img: your_story,
  },
  {
    date: "Jan 01, 2024",
    title:
      "Visionary leadership is driving the revolution in the CeDeFi ecosystem by Financial Crypto",
    subTitle: "",
    link: "https://cxotoday.com/interviews/visionary-leadership-is-driving-the-revolution-in-the-cedefi-ecosystem-by-financial-crypto/",
    img: cxo_today,
  },
  {
    date: "January 29, 2024",
    title: "NFT Metta Exclusive interview with Pranav Srivan Elankovan",
    subTitle: "Founder of crypto trading platform CrypFi",
    link: "https://www.nftmetta.com/nftmetta-exclusive-interview-with-pranav-srivan-elankovan-founder-of-crypto-trading-platform-crypfi/",
    img: nft_metta,
  },
];

const NewsSection = () => {
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style }} onClick={onClick}>
        {/* <RightArrowIcon /> */}rr
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style }} onClick={onClick}>
        {/* <LeftArrowIcon /> */}ll
      </div>
    );
  }
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    draggable: false,
    arrows: false,

    responsive: [
      {
        breakpoint: 1368,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          arrows: false,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 467,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          arrows: false,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
    ],
  };
  return (
    <section className="news-sec">
      <Container>
        <h2 className="text-center">
          In the <span className="text-orange">news</span>
        </h2>
        <Slider {...settings}>
          {newsData?.map((item, index) => (
            <div key={index}>
              <NewsCard
                link={item.link}
                img={item.img}
                date={item.date}
                title={item.title}
                subTitle={item.subTitle}
              />
            </div>
          ))}
        </Slider>
      </Container>
    </section>
  );
};

export default NewsSection;
