import './PlatformCard.scss'

function PlatformCard(props) {
    return (
        <>
            <div className={`Platform_Card ${props.className}`}>
                {props.icon}
                <h5>{props.heading}</h5>
            </div>
        </>
    );
}

export default PlatformCard;