import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
import { FutureLogo } from "../../../../../assets/svg/SvgIcons";
import "./FutureFinance.scss";

function FutureFinance() {
  return (
    <>
      <section className="FutureFinance">
        <Container>
          <motion.div
            transition={{ duration: 0.5 }}
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <div className="Finance_wrap">
              <motion.div
                transition={{ duration: 0.7 }}
                initial={{ opacity: 0, y: 150 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
              >
                <FutureLogo />
                <h3>Let’s Build The Future Of Finance. Together</h3>
                <h2>You Name It. We Have It.</h2>
              </motion.div>
            </div>
          </motion.div>
        </Container>
      </section>
    </>
  );
}

export default FutureFinance;
