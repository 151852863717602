import React, { useEffect, useState } from "react";
import HeroSection from "./Sections/HeroSection/HeroSection";
import Header from "../../common/ui/Header/Header";
import AccordianSection from "./Sections/AccordianSection/AccordianSection";
import Footer from "../../common/ui/Footer/Footer";
import StackSection from "./Sections/StackSection/StackSection";
import GraphSection from "./Sections/GraphSection/GraphSection";
import TradeCrypfi from "./Sections/TradeCrypfi/TradeCrypfi";
import CryptoPlatform from "./Sections/CryptoPlatform/CryptoPlatform";
import FutureFinance from "./Sections/FutureFinance/FutureFinance";
import SignupSection from "./Sections/SignupSection/SignupSection";
import handleViewport from "react-in-viewport";
import NewsSection from "./Sections/NewsSection/NewsSection";
import "./Home.scss";

const ViewPortBlock = handleViewport(StackSection);
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="home-page">
      <Header />
      <HeroSection />
      <GraphSection />
      <AccordianSection />
      <TradeCrypfi />
      <CryptoPlatform />
      <FutureFinance />
      {/* <StackSection /> */}
      <ViewPortBlock onEnterViewport={() => {}} onLeaveViewport={() => {}} />
      <NewsSection />
      <SignupSection />
      <Footer />
    </main>
  );
};

export default Home;
