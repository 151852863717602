import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-scroll";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../../../assets/images/logo.svg";
import "./Header.scss";

const Header = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100 && window.scrollY < 105) {
        window.scrollTo(0, 105);
      }
      setScroll(window.scrollY > 100);
    });
  }, []);
  return (
    <Navbar className={`header ${scroll && "scrolled"}`}>
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} alt="logo" />
        </Navbar.Brand>

        <Nav className="mx-md-auto">
          {/* <Nav.Link href="#products">Products</Nav.Link> */}

          <Link
            className="nav-link cursor-pointer"
            activeClass="active"
            to="#products"
            spy={true}
            offset={-70}
            duration={500}
          >
            Products
          </Link>
          <Nav.Link href="https://docs.crypfi.io/" target="_blank">
            Docs
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Header;
