import { Col, Container, Row } from "react-bootstrap";
import { motion } from "framer-motion";
import CommonCard from "../../../../common/ui/CommonCard/CommonCard";
import tradecard_icon1 from "../../../../../assets/icons/tradecard_icon1.png";
import tradecard_icon2 from "../../../../../assets/icons/tradecard_icon2.png";
import tradecard_icon3 from "../../../../../assets/icons/tradecard_icon3.png";
import tradecard_icon4 from "../../../../../assets/icons/tradecard_icon4.png";
import tradecard_icon5 from "../../../../../assets/icons/tradecard_icon5.png";
import tradecard_icon6 from "../../../../../assets/icons/tradecard_icon6.png";
import tradecard_icon7 from "../../../../../assets/icons/tradecard_icon7.png";

import "./TradeCrypfi.scss";

function TradeCrypfi() {
  return (
    <>
      <section className="TradeCrypfi">
        <Container>
          <motion.div
            transition={{ duration: 0.7 }}
            initial={{ opacity: 0, y: 75 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <div className="Trade_heading text-center">
              <h2>
                Why trade on <span className="text-orange">CrypFi?</span>
              </h2>
            </div>
          </motion.div>
          <Row className="justify-content-center">
            <Col lg={3} md={4} xs={6} className="mb-4">
              <motion.div
                transition={{ duration: 0.7 }}
                initial={{ opacity: 0, y: 75 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="h-100"
              >
                <CommonCard
                  icon={
                    <img
                      src={tradecard_icon1}
                      alt="icon"
                      className="Zero-gas"
                    />
                  }
                  title={"Zero Gas Fees"}
                />
              </motion.div>
            </Col>
            <Col lg={3} md={4} xs={6} className="mb-4">
              <motion.div
                transition={{ duration: 0.7 }}
                initial={{ opacity: 0, y: 75 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="h-100"
              >
                <CommonCard
                  icon={<img src={tradecard_icon2} alt="icon" />}
                  title={"Self Custody"}
                  className="Cardbg-gren"
                />
              </motion.div>
            </Col>
            <Col lg={3} md={4} xs={6} className="mb-4">
              <motion.div
                transition={{ duration: 0.7 }}
                initial={{ opacity: 0, y: 75 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="h-100"
              >
                <CommonCard
                  icon={<img src={tradecard_icon3} alt="icon" />}
                  title={"Leverage upto 50x"}
                  className="Cardbg-lightgren"
                />
              </motion.div>
            </Col>
            <Col lg={3} md={4} xs={6} className="mb-4">
              <motion.div
                transition={{ duration: 0.7 }}
                initial={{ opacity: 0, y: 75 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="h-100"
              >
                <CommonCard
                  icon={<img src={tradecard_icon4} alt="icon" />}
                  title={"No slippage"}
                  className="Cardbg-pink"
                />
              </motion.div>
            </Col>

            <Col lg={3} md={4} xs={6} className="mb-4 mb-lg-0">
              <motion.div
                transition={{ duration: 0.7 }}
                initial={{ opacity: 0, y: 75 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="h-100"
              >
                <CommonCard
                  icon={
                    <img
                      src={tradecard_icon7}
                      alt="icon"
                      className="Zero-gas"
                    />
                  }
                  title={"Super cheap trading fee"}
                  className="Cardbg-yellow"
                />
              </motion.div>
            </Col>
            <Col lg={3} md={4} xs={6} className="mb-4 mb-lg-0">
              <motion.div
                transition={{ duration: 0.7 }}
                initial={{ opacity: 0, y: 75 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="h-100"
              >
                <CommonCard
                  icon={<img src={tradecard_icon5} alt="icon" />}
                  title={"Super Fast"}
                  className="Cardbg-purple"
                />
              </motion.div>
            </Col>
            <Col lg={3} md={4} xs={6}>
              <motion.div
                transition={{ duration: 0.7 }}
                initial={{ opacity: 0, y: 75 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="h-100"
              >
                <CommonCard
                  icon={<img src={tradecard_icon6} alt="icon" />}
                  title={"CrypFi ID"}
                  className="Cardbg-blue"
                />
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default TradeCrypfi;
