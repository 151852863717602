import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
import graphimage from "../../../../../assets/images/chart_screen.png";
import "./GraphSection.scss";

function GraphSection() {
  return (
    <>
      <section className="GraphSection">
        <Container>
          <motion.div
            className="Graph-wrap"
            transition={{ duration: 0.5 }}
            initial={{ opacity: 0, scale: 0.7 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
          >
            <img src={graphimage} alt="img" />
          </motion.div>
        </Container>
      </section>
    </>
  );
}

export default GraphSection;
