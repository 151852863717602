import React, { useState } from "react";
import { motion } from "framer-motion";
import { Container } from "react-bootstrap";
import CommonButton from "../../../../common/ui/CommonButton/CommonButton";
import "./SignupSection.scss";
import axios from "axios";
import { toast } from "react-toastify";

const SignupSection = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    emailAPI();
  };

  function ValidateEmail(email) 
  {
   if (/^(.+)@(.+)$/.test(email))
    {
      setEmailError("")
      return setEmail(email)
      
    }
    setEmailError("You have entered an invalid email address!")
      return setEmail(email)
  }



  const emailAPI = () => {

    const data = {
      username: name,
      email: email,
    };
    axios({
      url: "https://api-qa.crypfi.io/users/v1/user/welcomeMail",
      method: "POST",
      data: data,
    })
      .then((res) => {
        if (res.status == 200) {
          setName("");
          setEmail("");
        }
        if (res.data.status == 200) {
          toast.success(
            "Thank you for your Interest, We will get back to you soon!"
          );
        }
      })

      .catch((err) => {
        console.log("sdfqwerwerwer", err);
        toast.error("Something went wrong. Please try again ");
      });
  };

  return (
    <section className="signup-sec">
      <Container>
        <div className="signup-sec_wrap">
          <motion.div
            transition={{ duration: 0.4 }}
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <h3 className="text-capitalize text-center">
              Signup to be in a list of{" "}
              <span className="text-orange">beta</span> users and get early
              access!
            </h3>
          </motion.div>
          <motion.div
            transition={{ duration: 0.4 }}
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <div className="signup-sec_form">
              <form>
                <div className="signup-sec_form_wrap">
                  <label>Your Name</label>
                  <input
                    type="text"
                    placeholder="Enter your name"
                    required
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
                <div className="signup-sec_form_wrap">
                  <label>Email ID</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter Your Email ID"
                    // required
                    value={email}
                    onChange={(e) => {
                      // if (emailRegex.test(e.target.value)) {
                        ValidateEmail(e.target.value)
                        //  setEmail(e.target.value);
                      // }
                    }}
                  />
                  <p style={{color:'red',marginTop:'10px'}}>{emailError}</p>
                </div>
                <div className="text-center">
                  <CommonButton
                    title="Be a beta tester"
                    className="mx-auto text-capitalize"
                    disabled={!name || !email || emailError}
                    onClick={(e) => handleSubmit(e)}
                  />
                </div>
              </form>
            </div>
          </motion.div>
        </div>
      </Container>
    </section>
  );
};

export default SignupSection;
