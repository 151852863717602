import Matter, { use } from "matter-js"; // Import Matter.js if you are using it

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import "./StackSection.scss";

const StackSection = (props) => {
  const { inViewport, forwardedRef } = props;
  const [numbering, setNumbering] = useState(0);

  const words = useMemo(() => [
    {
      label: "Staking",
      color: "#8BACFF",
    },
    {
      label: "RWA",
      color: "#C597FF",
    },
    {
      label: "Lending",
      color: "#FF9D97",
    },
    {
      label: "Crypto",
      color: "#FF8B45",
      character_width: 20,
    },
    {
      label: "Trading",
      color: "#DEFF97",
    },
    {
      label: "NFTs",
      color: "#97E6FF",
    },
    {
      label: "Derivatives",
      color: "#FFEA70",
    },
    {
      label: "AI",
      color: "#56CCFF",
    },
    {
      label: "Swap",
      color: "#A5ED93",
    },
    {
      label: "Perpetuals",
      color: "#97E6FF",
    },
    {
      label: "Options",
      color: "#FFB097",
    },
    {
      label: "CeDeFi",
      color: "#97FFB4",
    },
  ], [])
  const handleStack = useCallback(() => {
    const GRAVITY = 0.2;
    const ELEMENT_FOR_RENDERING_CANVAS = document.getElementById("areaToRender");
    ELEMENT_FOR_RENDERING_CANVAS.innerHTML = "";
    let bodyWidth = document.body.clientWidth;
    let CANVAS_HEIGHT = bodyWidth > 767 ? 600 : 400;
    let CANVAS_WIDTH = bodyWidth > 1680 ? 1603 : bodyWidth > 1400 ? 1400 : bodyWidth - 30;
    const RESITUTION = 0.6;

    // module aliases
    let Engine = Matter.Engine;
    const Render = Matter.Render;
    const Runner = Matter.Runner;
    const Bodies = Matter.Bodies;
    const Mouse = Matter.Mouse;
    const MouseConstraints = Matter.MouseConstraint;
    let Composite = Matter.Composite;

    // create an engine
    let engine = Engine.create();
    engine.gravity.scale = GRAVITY * 0.001;
    let world = engine.world;

    // create a renderer
    let render = Render.create({
      element: ELEMENT_FOR_RENDERING_CANVAS,
      engine: engine,
      options: {
        wireframes: false,
        width: CANVAS_WIDTH,
        height: CANVAS_HEIGHT,
        showAngleIndicator: true,
        background: "transparent",
      },
    });
    render.canvas.addEventListener("wheel", handleMouseScroll);
    render.canvas.addEventListener("mouseover", handleMouseClick);

    function handleMouseClick() {
      const collGet = document.getElementById("areaToRender");
      collGet.style.zIndex = "1";
    }
    function handleMouseScroll() {
      const collGet = document.getElementById("areaToRender");
      collGet.style.zIndex = "unset";
    }
    let boundry = [
      Bodies.rectangle(5, CANVAS_HEIGHT / 2, 10, CANVAS_HEIGHT, {
        isStatic: true,
        render: { fillStyle: "transparent" },
      }), // left boundry
      Bodies.rectangle(CANVAS_WIDTH / 2, 5, CANVAS_WIDTH, 10, {
        isStatic: true,
        render: { fillStyle: "transparent" },
      }), // top boundry
      Bodies.rectangle(CANVAS_WIDTH - 5, CANVAS_HEIGHT / 2, 10, CANVAS_HEIGHT, {
        isStatic: true,
        render: { fillStyle: "transparent" },
      }), // right boundry
      Bodies.rectangle(CANVAS_WIDTH / 2, CANVAS_HEIGHT - 5, CANVAS_WIDTH, 10, {
        isStatic: true,
        render: { fillStyle: "transparent" },
      }), // bottom boundry
    ];

    // text rectangle
    let entities = [];
    function randomIntFromInterval(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    }

    let allBoxes = document.getElementById("all_boxes");
    const animationRender = (entity, index) => {
      let { x, y } = entity.position;
      let tile = allBoxes.querySelectorAll("span")[index];
      tile.style.left = `${x - tile.clientWidth / 2}px`;
      tile.style.top = `${y - tile.clientHeight / 2}px`;
      tile.style.transform = `rotate(${entity.angle}rad)`;
    };
    function handleTiles() {
      allBoxes.innerHTML = "";
      words.forEach((word, index) => {
        allBoxes.innerHTML += `<span style="background-color: ${word.color}; border-color: ${word.color}">${word.label}</span>`;
        let tile_width = allBoxes.querySelectorAll(".all_boxes span")[index].offsetWidth;
        let tile_height = allBoxes.querySelectorAll(".all_boxes span")[index].offsetHeight;
        let box = Bodies.rectangle(randomIntFromInterval(0, CANVAS_WIDTH - 200), 80, tile_width, tile_height, {
          restitution: RESITUTION,
          render: { fillStyle: "transparent", },
          chamfer: { radius: 16, },
        }
        );
        entities.push(box);
      });
      entities.forEach(function (entity, index) {
        animationRender(entity, index);
      });
    }
    handleTiles();

    (function rerender() {
      entities.forEach((entity, index) => {
        animationRender(entity, index);
      });
      Matter.Engine.update(engine);
      requestAnimationFrame(rerender);
    })();

    // add all of the bodies to the world
    Composite.add(engine.world, entities);
    Composite.add(engine.world, boundry);

    // run the renderer
    Render.run(render);
    // create runner
    let runner = Runner.create();
    // run the engine
    Runner.run(runner, engine);
    let mouse = Mouse.create(render.canvas),
      mouseConstraint = MouseConstraints.create(engine, {
        mouse: mouse,
        constraint: {
          stiffness: 0.2,
          render: {
            visible: false,
          },
        },
      });

    Composite.add(world, mouseConstraint);

    // keep the mouse in sync with rendering
    render.mouse = mouse;
    Render.lookAt(render, {
      min: { x: 0, y: 0 },
      max: { x: CANVAS_WIDTH, y: CANVAS_HEIGHT },
    });
  }, [words])

  useEffect(() => {
    if (inViewport) {
      handleStack(numbering);
    }
  }, [inViewport]);
  return (
    <div className="stacks" ref={forwardedRef}>
      <Container>
        <div className="stack-sec">
          <div id="all_boxes" className="all_boxes"></div>
          <div id="areaToRender"></div>
        </div>
      </Container>
    </div>
  );
};

export default StackSection;
