import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
import {
  ExternalLinkIcon,
  PlatformIcon1,
  PlatformIcon2,
  PlatformIcon3,
  PlatformIcon4,
} from "../../../../../assets/svg/SvgIcons";
import PlatformCard from "../../../../common/ui/PlatformCard/PlatformCard";
import CommonButton from "../../../../common/ui/CommonButton/CommonButton";
import "./CryptoPlatform.scss";

function CryptoPlatform() {
  return (
    <>
      <section className="CryptoPlatform">
        <Container>
          <motion.div
            transition={{ duration: 0.7 }}
            initial={{ opacity: 0, y: 75 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <div className="Trade_heading text-center">
              <h2>
                We are not just a trading platform, <br /> We are a crypto
                focused <span className="text-orange">neo b*nk.</span>
              </h2>
            </div>
          </motion.div>
          <motion.div
            transition={{ duration: 0.7, delay: 0.3 }}
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
          >
            <div className="Platform-wrap mb-4 mb-md-5">
              <PlatformCard
                icon={<PlatformIcon1 />}
                heading={"Revenue based Financing"}
              />
              <PlatformCard
                icon={<PlatformIcon2 />}
                heading={"Emerging market credit"}
                className="plat-base1"
              />
            </div>
          </motion.div>
          <motion.div
            transition={{ duration: 0.7, delay: 0.5 }}
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
          >
            <div className="Capital-wrap">
              <PlatformCard
                icon={<PlatformIcon3 />}
                heading={"Institutional capital"}
                className="plat-base2"
              />
              <PlatformCard
                icon={<PlatformIcon4 />}
                heading={"Buy Now Pay Later"}
                className="plat-base3"
              />
            </div>
          </motion.div>
          <motion.div
            transition={{ duration: 0.7, delay: 0.5 }}
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <div className="Product-text text-center">
              <h4>
                <span className="text-orange">CrypFi Lends</span> an Unique
                Product Offering for institutions & companies.
              </h4>
              {/* <CommonButton
                title="Learn More"
                className="mx-auto"
                icon={<ExternalLinkIcon />}
              /> */}
            </div>
          </motion.div>
        </Container>
      </section>
    </>
  );
}

export default CryptoPlatform;
