import React from "react";
import { motion } from "framer-motion";
import SocialLinks from "../SocialLinks/SocialLinks";
import footer_logo from "../../../../assets/images/footer_logo.svg";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer_social">
        <span className="footer_social_logo">
          <motion.div
            transition={{ duration: 0.4 }}
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <img src={footer_logo} alt="footer-logo" />
          </motion.div>
        </span>
        <motion.div
          transition={{ duration: 0.6 }}
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          <SocialLinks />
        </motion.div>
        <motion.p
          transition={{ duration: 0.6 }}
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          Trinal Web Private Limited. <br /> D-62, ANM Building, Thillai Nagar,
          Thiruchirappalli. Tamil Nadu. 620018.
        </motion.p>
      </div>
      <motion.div
        transition={{ duration: 0.4 }}
        initial={{ opacity: 0, scale: 0.5 }}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true }}
      >
        <div className="footer_copyright">
          <p>© {new Date().getFullYear()} All rights reserved by Crypfi</p>
        </div>
      </motion.div>
    </footer>
  );
};

export default Footer;
