import React from "react";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
import CommonButton from "../../../../common/ui/CommonButton/CommonButton";
import {
  BnbIcon,
  BtcIcon,
  EthIcon,
  ExternalLinkIcon,
  PolygenIcon,
  TestIcon,
  TestIcon2,
  TestIcon3,
} from "../../../../../assets/svg/SvgIcons";

import "./HeroSection.scss";

const HeroSection = () => {
  return (
    <section className="hero-section">
      <Container className="text-center">
        <div className="hero-section__wrap">
          <motion.div
            initial={{ opacity: 0, scale: 0.3 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.9 }}
          >
            <h1>
              World’s First <br /> <span className="text-orange">CeDeFi </span>
              Ecosystem
            </h1>
            <h4>Bridging the Best of Both Worlds</h4>
            <CommonButton
              // role="a-tag"
              // title="Try Now"
              // to="https://testnet.crypfi.io/"
              title="Coming Soon"
              className="mx-auto d-inline-flex"
              icon={<ExternalLinkIcon />}
              disabled
            />
          </motion.div>
          <div className="icons-wrap icons-wrap-one">
            <EthIcon />
          </div>
          <div className="icons-wrap icons-wrap-two">
            <PolygenIcon />
          </div>
          <div className="icons-wrap icons-wrap-three">
            <BnbIcon />
          </div>
          <div className="icons-wrap icons-wrap-four">
            <BtcIcon />
          </div>
          <div className="icons-wrap icons-wrap-five">
            <TestIcon />
          </div>
          <div className="icons-wrap icons-wrap-six">
            <TestIcon2 />
          </div>
          <div className="icons-wrap icons-wrap-seven">
            <TestIcon3 />
          </div>
        </div>
        <motion.div
          transition={{ duration: 0.5 }}
          initial={{ opacity: 0, scale: 0.5, y: 100 }}
          whileInView={{ opacity: 1, scale: 1, y: 0 }}
          viewport={{ once: true }}
        >
          <h6 className="text-capitalize">
            “Trade Derivative Contracts without limits:{" "}
            <span className="text-orange">
              low fees, deep liquidity, multiple blockchains.”{" "}
            </span>
          </h6>
          <h2 className="h1 text-capitalize">
            Built on top of major{" "}
            <span className="text-orange">EVM layer2</span> chains.
          </h2>
        </motion.div>
      </Container>
    </section>
  );
};

export default HeroSection;
