import React, { useEffect, useRef } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Accordion from "react-bootstrap/Accordion";
import { Container } from "react-bootstrap";
import {
  EarnAndBorrowIcon,
  MarginIcon,
  MultichainIcon,
  OrderbookIcon,
  TradingIcon,
} from "../../../../../assets/svg/SvgIcons";
import "./AccordianSection.scss";

const accordianContent = [
  {
    icon: <TradingIcon />,
    title: "Trade",
    name: "Perps",
    tabsData: [
      {
        name: "Perps",
        content: (
          <>
            Go long or short based on your predictions on the market on chain.
            Use leverage uptp 50x, to amplify risk and hedge accordingly.
          </>
        ),
      },
      {
        name: "Options",
        content: (
          <>
            Buy or sell options on chain based on your bets on the future price.
            Go full on with your own strategies or go ahead with our predefined
            strategies. No physical delivery, entirely cash settled of ease of
            use
          </>
        ),
      },
    ],
    no: "01",
  },
  {
    icon: <MarginIcon />,
    title: "Margin",
    name: "Isolated",
    tabsData: [
      {
        name: "Isolated",
        content: (
          <>
            Trade cryptocurrencies with confidence using our Isolated Margin
            feature, safeguarding your assets and managing risk effectively.
          </>
        ),
      },
      {
        name: "Cross",
        content: (
          <>
            Unlock maximum trading flexibility with Cross Margin – optimize your
            crypto investments seamlessly.
          </>
        ),
      },
      {
        name: "Portfolio",
        content: (
          <>
            Elevate your crypto trading strategy with Portfolio Margin –
            maximize capital efficiency and gain a competitive edge.
          </>
        ),
      },
    ],
    no: "02",
  },
  {
    icon: <EarnAndBorrowIcon />,
    title: "Earn & Borrow",
    name: "Earn",
    tabsData: [
      {
        name: "Earn",
        content: (
          <>
            We are democratising earning on chain. Earn yields automatically
            from the collateral and the assets you deposit.
          </>
        ),
      },
      {
        name: "Borrow",
        content: (
          <>
            Borrow assets against your margin to trade spot with leverage.
            Withdraw borrowed assets to utilize elsewhere.
          </>
        ),
      },
    ],
    no: "03",
  },
  {
    icon: <OrderbookIcon />,
    title: "Orderbook + AMM",
    name: "Orderbook",
    tabsData: [
      {
        name: "Orderbook",
        content: (
          <>
            Dive into the heart of crypto markets with our dynamic Orderbook –
            make informed trading decisions in real-time.
          </>
        ),
      },
      {
        name: "AMM",
        content: (
          <>
            Experience efficient and automated trading with our AMM (Automated
            Market Maker) – revolutionizing liquidity provision in the crypto
            space.
          </>
        ),
      },
    ],
    no: "04",
  },
  {
    icon: <MultichainIcon />,
    title: "Multi Chain",
    name: "Multichain",
    tabsData: [
      {
        name: "Multichain",
        content: (
          <>
            Seamlessly trade across multiple blockchain networks with our
            Multichain platform – expanding your crypto horizons.
          </>
        ),
      },
    ],
    no: "05",
  },
];

// Register the ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

const AccordianSection = () => {
  const bodyWidth = document.body.clientWidth;
  const accordionRef = useRef();
  useEffect(() => {
    let GSAp = gsap.timeline({
      scrollTrigger: {
        trigger: accordionRef.current,
        scrub: 0.2,
        start: "start",
        markers: false,
        pin: true,
        // pinSpacing: false,
        end: "+=2000",
        pushFollowers: false,
      },
    });
    GSAp.from(".accordion-collapse", {
      height: "300",
    })
      .to(".accordion-collapse", {
        height: "0",
        duration: 1,
        ease: "none",
        stagger: {
          each: 2,
          yoyo: true,
          // repeat: 1,
        },
      })
      // .from(
      //   ".accordion-button",
      //   {
      //     color: "#0b0b0b",
      //     fontSize: " 4.6rem",
      //     fontWeight: " 600",
      //     duration: 1,
      //     ease: "none",
      //     paddingBottom: "2.6rem",
      //     stagger: {
      //       each: 2,
      //       yoyo: true,
      //     },
      //   },
      //   0
      // )
      .to(
        ".accordion-button",
        {
          color: "rgba(97, 97, 97, 0.5)",
          fontSize: bodyWidth > 1199 ? "3rem" : bodyWidth > 768 ? "2rem" : "1.4rem",
          fontWeight: "400",
          paddingBottom: "2.6rem",
          ease: "none",
          duration: 1,
          stagger: {
            each: 2,
            yoyo: true,
          },
        },
        0
      )
      .to(
        ".count_text",
        {
          display: "none",
          ease: "none",
          duration: 1,
          stagger: {
            each: 2,
            yoyo: true,
          },
        },
        0
      );
  }, [bodyWidth]);

  return (
    <section className="accordian-sec" id="#products">
      <Container>
        <h2 className="text-center">
          One <span className="text-orange">Exchange</span> Platform. <br />
          Everything you need to grow.
        </h2>
        <Accordion ref={accordionRef} style={{ height: "400px" }}>
          {accordianContent.map((item, index) => {
            return (
              <Accordion.Item key={index}>
                <Accordion.Header>{item.title}</Accordion.Header>
                <Accordion.Body>
                  <div className="content-wrap">
                    <span className="content-wrap-icon">{item.icon}</span>
                    <div className="common-tabs">
                      <Tab.Container defaultActiveKey={item.name}>
                        <Nav>
                          {item.tabsData?.map((data, index) => {
                            return (
                              <Nav.Item key={index}>
                                <Nav.Link eventKey={data.name}>
                                  {data.name}
                                </Nav.Link>
                              </Nav.Item>
                            );
                          })}
                        </Nav>
                        <Tab.Content>
                          {item.tabsData?.map((data, index) => {
                            return (
                              <Tab.Pane key={index} eventKey={data.name}>
                                <p>{data.content}</p>
                              </Tab.Pane>
                            );
                          })}
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </Accordion.Body>
                <span className="count_text">{item.no}</span>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Container>
    </section>
  );
};

export default AccordianSection;
