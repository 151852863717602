import { Fragment, useEffect, useState } from "react";
// import {
//   CopyLinkIcon,
//   DownloadNewIcon,
//   InstagramIcon,
//   LinkedinIcon,
//   MoreIcon,
//   TelegramIcon,
//   TwitterNewIcon,
//   WhatsappIcon,
// } from "../../../assets/svg/SvgIcons";
import "./SocialLinks.scss";
import {
  DiscordIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  YoutubeIcon,
} from "../../../../assets/svg/SvgIcons";
// import { SOCILA_LINKS } from "../../../constants/constants";

const SOCILA_LINKS = [
  {
    icon: <TwitterIcon />,
    href: "https://twitter.com/CrypFi_io",
  },

  {
    icon: <TelegramIcon />,
    href: "https://t.me/crypfi_group",
  },
  {
    icon: <InstagramIcon />,
    href: "https://www.instagram.com/crypfi_io/",
  },
  {
    icon: <DiscordIcon />,
    href: "https://discord.com/invite/y7sg3SgsqR",
  },
  // {
  //   icon: <FacebookIcon />,
  //   href: "https://www.facebook.com/",
  // },
  {
    icon: <YoutubeIcon />,
    href: "https://www.youtube.com/@Crypfi_io",
  },
];

const SocialLinks = () => {
  return (
    <ul className="social-links">
      {SOCILA_LINKS.map((item, key) => {
        return (
          <Fragment key={key}>
            <li>
              <a href={item.href} rel="noreferrer" target="_blank">
                <span className="social-links-icon">{item.icon}</span>
              </a>
            </li>
          </Fragment>
        );
      })}
    </ul>
  );
};

export default SocialLinks;
