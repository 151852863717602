import React from "react";
import "./NewsCard.scss";

const NewsCard = ({ link, img, date, title, subTitle }) => {
  return (
    <a href={link} target="_blank" className="news-card">
      <div className="news-card__img">
        <img src={img} alt="article-img" />
      </div>
      <div className="news-card__content">
        <small>{date}</small>
        <h6>{title}</h6>
        <p>{subTitle}</p>
      </div>
    </a>
  );
};

export default NewsCard;
